/* src/main.css */
:root{
  /* dark shades of primary color*/
  --clr-primary-1: hsl(184, 91%, 17%);
  --clr-primary-2: hsl(185, 84%, 25%);
  /* primary/main color */
  --clr-primary-5: hsl(185, 62%, 45%);
  /* lighter shades of primary color */
  --clr-primary-6: hsl(185, 57%, 50%);
  --clr-primary-7: hsl(184, 65%, 59%);
  /* darkest grey - used for headings */
  --clr-1: hsl(0, 30%, 58%);
  --clr-2: hsl(0, 0%, 0%);
  /* grey used for paragraphs */
  --clr-grey-5: hsl(210, 22%, 49%);
  --clr-grey-6: hsl(209, 23%, 60%);
  --clr-white: #fff;
  --ff-primary: 'Lato', sans-serif;
  --transition: all 0.3s linear;
  --spacing: 0.25rem;
  --radius: 0.5rem;
  --light-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  --dark-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

body {
  /* change this to something else  */
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  background: var(--clr-white);
  color: var(--clr-2);
  line-height: 1.5;
  font-size: 0.875rem;
}


/*
  =============== 
  Entry
  ===============
*/
.entry-container {
  --background-image: url('./brick_background.jpg');
  background: var(--background-image) center/cover no-repeat;
  background-attachment: fixed;
  /* transition: background-image 1s ease-in-out; */
  /* position: relative; */
  overflow: hidden;
  height: 100vh;
  width: 100%;
}

.content-wrapper {
  /* position: relative; */
  /* overflow: hidden; */
  z-index: 2; /* Ensure content is above the background */
}

/*
  =============== 
  Transition
  ===============
*/
.fade-enter {
  opacity: 0;
  transform: translateY(20px); /* Optional: slide in effect */
}

.fade-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 300ms ease-in-out, transform 300ms ease-in-out;
}

.fade-exit {
  opacity: 1;
  transform: translateY(0);
}

.fade-exit-active {
  opacity: 0;
  transform: translateY(20px); /* Optional: slide out effect */
  transition: opacity 300ms ease-in-out, transform 300ms ease-in-out;
}

/* Add these styles to your main.css file */

/*
  =============== 
  Welcome Sign
  ===============
*/
.welcome-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
  padding: 0 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.welcome-content {
  background: rgba(255, 255, 255, 0.85);
  backdrop-filter: blur(5px);
  border-radius: 12px;
  padding: 2.5rem 3rem;
  width: 100%;
  max-width: 800px;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.08);
  border-left: 4px solid #926b51;
  transform: translateY(0);
  transition: transform 0.4s ease, box-shadow 0.4s ease;
}

.welcome-content:hover {
  transform: translateY(-5px);
  box-shadow: 0 12px 40px rgba(0, 0, 0, 0.12);
}

.welcome-title {
  color: #926b51;
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
  font-weight: 600;
  letter-spacing: -0.5px;
  opacity: 0; /* Start invisible */
}

.title-fade-in {
  animation: titleFadeIn 1.2s ease-out forwards;
}

@keyframes titleFadeIn {
  from {
    opacity: 0;
    transform: translateY(-15px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.welcome-text {
  color: #333;
  font-size: 1.1rem;
  line-height: 1.7;
  margin: 0;
  font-weight: 400;
  letter-spacing: 0.2px;
}

.cursor {
  color: #926b51;
  font-weight: bold;
  margin-left: 1px;
  font-size: 1.2em;
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
}

@keyframes blink-caret {
  from, to { opacity: 0; }
  50% { opacity: 1; }
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .welcome-content {
    padding: 2rem;
  }
  
  .welcome-title {
    font-size: 2rem;
  }
  
  .welcome-text {
    font-size: 1rem;
  }
}

/*
  =============== 
  Login & Create Account
  ===============
*/
.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .credential-form {
    font-family: 'Arial', sans-serif;
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    width: 250px;
    text-align: center;
    transition: var(--transition);
  }
  .credential-form:hover {
    transform: scale(1.01)
  }

  .credential-form h2 {
    margin-bottom: 20px;
  }
  
  .form-group {
    margin-bottom: 15px;
    text-align: left;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .form-group input {
    width: 94%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .login-button, .switch-button {
    width: 100%;
    padding: 10px;
    background-color: #926b51;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 10px;
  }

  .logout-button {
    width: 100px;
    padding: 10px;
    background-color: #ff5100;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 1px;
    position: absolute;
    top: 0;
  }
  
  .login-button:hover, .switch-button:hover {
    background-color: #b0856a;
  } 

/*
  =============== 
  Authentication (Login & Create Account)
  ===============
*/
.auth-container {
  display: flex;
  justify-content: center;
  align-items: center; /* Changed from flex-start to center */
  min-height: 80vh;
  padding: 2rem; /* Removed the excessive top padding */
  max-width: 1200px;
  margin: 0 auto;
}

.auth-content {
  background: rgba(255, 255, 255, 0.85);
  backdrop-filter: blur(5px);
  border-radius: 12px;
  padding: 2.5rem 3rem;
  width: 100%;
  max-width: 500px;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.08);
  border-left: 4px solid #926b51;
  transform: translateY(20px);
  opacity: 0;
  transition: transform 0.5s ease-out, opacity 0.5s ease-out, box-shadow 0.4s ease;
}

.auth-content:hover {
  box-shadow: 0 12px 40px rgba(0, 0, 0, 0.12);
}

.content-visible {
  transform: translateY(0);
  opacity: 1;
}

.auth-title {
  color: #926b51;
  font-size: 2rem;
  margin-bottom: 1.5rem;
  font-weight: 600;
  letter-spacing: -0.5px;
}

.auth-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.form-group label {
  color: #555;
  font-size: 0.9rem;
  font-weight: 500;
}

.form-group input {
  padding: 0.8rem 1rem;
  border-radius: 8px;
  border: 1px solid #ddd;
  background: rgba(255, 255, 255, 0.8);
  font-size: 1rem;
  transition: border 0.3s, box-shadow 0.3s;
}

.form-group input:focus {
  outline: none;
  border-color: #926b51;
  box-shadow: 0 0 0 3px rgba(146, 107, 81, 0.15);
}

.auth-button {
  background-color: #926b51;
  color: white;
  border: none;
  padding: 0.8rem 1.5rem;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  margin-top: 1rem;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.auth-button:hover {
  background-color: #7d5a44;
  transform: translateY(-2px);
}

.auth-button:active {
  transform: translateY(0);
}

.auth-error {
  background-color: rgba(220, 53, 69, 0.1);
  color: #dc3545;
  padding: 0.8rem;
  border-radius: 8px;
  font-size: 0.9rem;
  border-left: 3px solid #dc3545;
}

.auth-switch {
  margin-top: 1rem;
  text-align: center;
  font-size: 0.9rem;
  color: #555;
}

.auth-switch a {
  color: #926b51;
  text-decoration: none;
  font-weight: 500;
  transition: color 0.3s;
}

.auth-switch a:hover {
  color: #7d5a44;
  text-decoration: underline;
}

/* Add this to your existing Authentication styles */

/* Specific styles for the create account form to reduce height */
.auth-form.create-account {
  gap: 0.75rem; /* Reduced from 1rem */
}

.auth-form.create-account .form-group {
  gap: 0.3rem; /* Reduced from 0.5rem */
}

.auth-form.create-account .form-group input {
  padding: 0.6rem 0.8rem; /* Reduced vertical padding */
}

.auth-form.create-account .auth-button {
  padding: 0.6rem 1.5rem; /* Reduced vertical padding */
  margin-top: 0.75rem; /* Reduced from 1rem */
}

.auth-form.create-account .auth-switch {
  margin-top: 0.75rem; /* Reduced from 1rem */
}

/* Adjust responsive breakpoints for better small screen support */
@media (max-width: 768px) {
  .auth-content {
    padding: 2rem 2rem 1.5rem 2rem; /* Reduced bottom padding */
  }
  
  .auth-title {
    font-size: 1.75rem;
    margin-bottom: 1.2rem; /* Reduced from 1.5rem */
  }
  
  .form-group {
    margin-bottom: 0.5rem; /* Added to reduce spacing */
  }
}

@media (max-width: 480px) {
  .auth-container {
    padding: 1rem;
    min-height: auto; /* Remove minimum height constraint */
  }
  
  .auth-content {
    padding: 1.25rem 1.5rem 1.25rem 1.5rem;
  }
  
  .auth-title {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
  
  .form-group input {
    padding: 0.5rem 0.7rem;
    font-size: 0.95rem; /* Slightly smaller font */
  }
  
  .auth-button {
    padding: 0.6rem 1.2rem;
    margin-top: 0.75rem;
  }
  
  .auth-switch {
    font-size: 0.85rem;
    margin-top: 0.75rem;
  }
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .auth-container {
    padding: 2rem; /* Balanced padding */
  }
  .auth-content {
    padding: 2rem;
  }
  
  .auth-title {
    font-size: 1.75rem;
  }
}

@media (max-width: 480px) {
  .auth-container {
    padding: 1.5rem; /* Balanced padding */
    min-height: auto; /* Remove minimum height constraint */
  }
  
  .auth-content {
    padding: 1.5rem;
  }
  
  .form-group input {
    padding: 0.7rem;
  }
}

/*
  =============== 
  Navbar
  ===============
*/

.navbar {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 2rem;
  background-color: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(10px);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  z-index: 1000;
  width: 100%;
  box-sizing: border-box;
}

.nav-logo {
  height: 50px;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.nav-logo:hover {
  transform: scale(1.05);
}

/* Refined navigation transitions */

.nav-buttons-container {
  position: relative;
  display: flex;
  justify-content: flex-end;
  min-height: 50px;
  overflow: visible; /* Changed from hidden to visible */
  flex-grow: 1; /* Allow container to grow */
  max-width: 70%; /* Limit width to avoid crowding logo */
}

/* Improve positioning and stacking */
.nav-buttons-group {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: flex-end; /* Align consistently */
  flex-wrap: wrap;
  width: auto; /* Changed from 100% to auto */
  position: relative; /* Ensure position context */
}

/* Refine animation timing and easing */
.nav-transition-enter {
  position: absolute;
  opacity: 0;
  transform: translateY(-15px); /* Slightly more movement */
  width: 100%;
  display: flex;
  justify-content: flex-end;
  right: 0; /* Anchor to the right */
  z-index: 1; /* Stay below exiting buttons */
}

.nav-transition-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 400ms cubic-bezier(0.34, 1.56, 0.64, 1), 
              transform 400ms cubic-bezier(0.34, 1.56, 0.64, 1); /* Custom easing */
  transition-delay: 250ms; /* Slightly longer delay */
  z-index: 2; /* Move above exiting buttons once animation starts */
}

.nav-transition-exit {
  opacity: 1;
  transform: translateY(0);
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  right: 0; /* Anchor to the right */
  z-index: 2; /* Start above entering buttons */
}

.nav-transition-exit-active {
  opacity: 0;
  transform: translateY(15px); /* Slightly more movement */
  transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1), 
              transform 250ms cubic-bezier(0.4, 0, 0.2, 1); /* Material easing */
  z-index: 1; /* Move below entering buttons as they fade out */
}

/* Mobile-specific fixes */
@media (max-width: 768px) {
  .nav-buttons-container {
    justify-content: center;
    width: 100%;
  }

  .nav-transition-enter, 
  .nav-transition-exit,
  .nav-buttons-group {
    justify-content: center;
  }
}

.nav-button {
  background-color: transparent;
  color: #333;
  border: none;
  padding: 0.5rem 0.75rem; /* Smaller padding */
  font-size: 0.95rem; /* Slightly smaller font */
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease, color 0.3s ease;
  white-space: nowrap; /* Prevent text wrapping within buttons */
}

.nav-button:hover {
  color: #926b51;
  transform: translateY(-2px);
}

.logout-button {
  background-color: rgba(220, 53, 69, 0.1);
  color: #dc3545;
}

.logout-button:hover {
  background-color: rgba(220, 53, 69, 0.2);
  color: #c82333;
}

/* Responsive adjustments */
@media (max-width: 992px) {
  .navbar {
    padding: 1rem;
  }
  
  .nav-buttons-group {
    gap: 0.4rem;
  }
  
  .nav-button {
    padding: 0.4rem 0.6rem;
    font-size: 0.9rem;
  }
}

@media (max-width: 768px) {
  .navbar {
    flex-direction: column;
    padding: 1rem 0.5rem;
  }
  
  .nav-buttons-container {
    width: 100%;
    max-width: 100%;
    justify-content: center;
    margin-top: 0.5rem;
  }
  
  .nav-transition-enter,
  .nav-transition-exit,
  .nav-buttons-group {
    width: 100%;
    justify-content: center;
    right: auto; /* Remove right positioning */
  }
}

@media (max-width: 480px) {
  .navbar {
    padding: 0.75rem 0.25rem;
  }
  
  .nav-logo {
    height: 40px;
  }
  
  .nav-buttons-group {
    gap: 0.3rem;
  }
  
  .nav-button {
    padding: 0.3rem 0.4rem;
    font-size: 0.8rem;
    border-radius: 4px;
  }
}

/*
  =============== 
  Footer
  ===============
*/
.footer {
  background: var(--clr-white);
  padding: 0rem 1rem;

  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 98%;
}

.footer .contact-info {
  display: flex;
  flex-direction: column;
}

.footer .contact-info p {
  margin: 0.5rem 0;
}

.footer .copyright {
  position: absolute; 
  bottom: .05rem; 
  right: 1rem; 
  text-align: right;
  flex-grow: 1;
}

@media (max-width: 480px) {
  .footer {
    font-size: 10px;
  }
}


/*
  =============== 
  Dashboard & Payment
  ===============
*/
.dashboard-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 80vh;
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.dashboard-content {
  background: rgba(255, 255, 255, 0.85);
  backdrop-filter: blur(5px);
  border-radius: 12px;
  width: 100%;
  max-width: 900px;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.08);
  border-left: 4px solid #926b51;
  transform: translateY(20px);
  opacity: 0;
  transition: transform 0.5s ease-out, opacity 0.5s ease-out, box-shadow 0.4s ease;
}

.dashboard-content:hover {
  box-shadow: 0 12px 40px rgba(0, 0, 0, 0.12);
}

.content-visible {
  transform: translateY(0);
  opacity: 1;
}

.dashboard-panel {
  padding: 2.5rem 3rem;
}

.dashboard-panel-title {
  color: #926b51;
  font-size: 2rem;
  margin-bottom: 1.5rem;
  font-weight: 600;
  letter-spacing: -0.5px;
}

.dashboard-payment-status {
  margin-bottom: 2rem;
}

.dashboard-payment-status p {
  font-size: 1.1rem;
  line-height: 1.6;
  color: #333;
  margin-bottom: 1.5rem;
}

.dashboard-action {
  margin-top: 1.5rem;
}

.dashboard-button {
  background-color: #926b51;
  color: white;
  border: none;
  padding: 0.8rem 1.5rem;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.dashboard-button:hover {
  background-color: #7d5a44;
  transform: translateY(-2px);
}

.dashboard-button:active {
  transform: translateY(0);
}

.dashboard-content {
  font-size: 1.1rem;
  line-height: 1.6;
  color: #333;
}

.payment-success-message {
  background-color: rgba(40, 167, 69, 0.1);
  border-left: 3px solid #28a745;
  padding: 1rem 1.5rem;
  border-radius: 8px;
  margin-top: 1rem;
}

.payment-success-message p {
  color: #155724;
  margin: 0;
}

.lease-address {
  background-color: rgba(255, 255, 255, 0.7);
  padding: 1.5rem;
  border-radius: 8px;
  margin-top: 1.5rem;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.lease-address h3 {
  color: #926b51;
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-size: 1.2rem;
}

/* Animations */
.fade-enter {
  opacity: 0;
  transform: translateY(10px);
}

.fade-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 300ms, transform 300ms;
}

.fade-exit {
  opacity: 1;
  transform: translateY(0);
}

.fade-exit-active {
  opacity: 0;
  transform: translateY(-10px);
  transition: opacity 300ms, transform 300ms;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .dashboard-panel {
    padding: 2rem;
  }
  
  .dashboard-panel-title {
    font-size: 1.75rem;
  }
}

@media (max-width: 480px) {
  .dashboard-container {
    padding: 1rem;
  }
  
  .dashboard-panel {
    padding: 1.5rem;
  }
  
  .dashboard-panel-title {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
  
  .dashboard-button {
    width: 100%;
  }
}

.dashboard.welcome {
  position: fixed;
  height: 50px;
  width: 97%;
  top: 0;
  z-index: 1000;
  margin: 0;
}

.dashboard {
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); 
  width: 300px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Arial', sans-serif;
  position: relative;
  font-weight: bold;
}

.owed {
  background: linear-gradient(135deg, #926b51, #b3cfa3);
  border-radius: 4px;
  width: auto;
  height: 20px;
  position: absolute;
  top: 0;
  left: 5;
}

.dashboard.payment {
  font-family: 'Arial', sans-serif;
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  /* width: 350px; */
  width: 45%;
  transition: var(--transition);
  flex-direction: column;
}

.dashboard.payment label {
  margin-bottom: 5px;
  font-weight: bold;
}

.dashboard.dashboard.payment:hover {
  transform: scale(1.01)
}

.dashboard.analytics {
  font-family: 'Arial', sans-serif;
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  width: 350px;
  text-align: center;
  transition: var(--transition);
  transition: max-height 0.5s ease;
}

.dashboard.dashboard.analytics:hover {
  transform: scale(1.01)
}


.StripeElement {
  width: 100%;
  padding: 1em;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
}

/*
  =============== 
  Admin
  ===============
*/

.c-button.admin {
  font-size: smaller;
  background-color: transparent;
  color: #926b51;
}

.grid-buttons {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 1rem;
}
.c-button.admin:hover {
  background-color: #ffffff;
}

/* Admin panel animations and styling */

.admin-container {
  opacity: 0;
  transform: translateY(10px);
  transition: opacity 500ms ease, transform 500ms ease;
  padding: 2rem;
  background: rgba(255, 255, 255, 0.85);
  border-radius: 12px;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.08);
  border-left: 4px solid #926b51;
}

.admin-visible {
  opacity: 1;
  transform: translateY(0);
}

/* Staggered element animations */
.admin-title,
.admin-buttons,
.admin-grid-container,
.admin-submit-button {
  opacity: 0;
  transform: translateY(15px);
}

.admin-visible .admin-title {
  animation: admin-item-fade-in 400ms forwards;
  animation-delay: 100ms;
}

.admin-visible .admin-buttons {
  animation: admin-item-fade-in 400ms forwards;
  animation-delay: 200ms;
}

.admin-visible .admin-grid-container {
  animation: admin-item-fade-in 400ms forwards;
  animation-delay: 300ms;
  height: 500px;
  width: 100%;
  max-width: 750px;
}

.admin-visible .admin-submit-button {
  animation: admin-item-fade-in 400ms forwards;
  animation-delay: 400ms;
}

/* Animation keyframes */
@keyframes admin-item-fade-in {
  from {
    opacity: 0;
    transform: translateY(15px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Button styling to match dashboard */
.admin-button {
  margin-right: 10px;
  margin-bottom: 15px;
}

.remove-button {
  background-color: rgba(220, 53, 69, 0.1);
  color: #dc3545;
}

.remove-button:hover {
  background-color: rgba(220, 53, 69, 0.2);
  color: #c82333;
}

.admin-submit-button {
  margin-top: 20px;
}

/* Grid styling */
.admin-data-grid {
  background-color: white;
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid rgba(224, 224, 224, 0.8);
}

/* MUI DataGrid overrides */
.MuiDataGrid-root {
  border: none !important;
  font-family: inherit !important;
}

.MuiDataGrid-columnHeaders {
  background-color: rgba(146, 107, 81, 0.1);
}

.MuiDataGrid-cell:focus-within {
  outline: none !important;
}

.MuiDataGrid-row:hover {
  background-color: rgba(146, 107, 81, 0.05) !important;
}

/* Mobile-friendly Admin DataGrid styles */

/* Grid container */
.admin-grid-container {
  width: 100%;
  overflow-x: auto; /* Enable horizontal scrolling */
  -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS */
  position: relative;
}

/* Base styles for DataGrid */
.admin-data-grid {
  background-color: white;
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid rgba(224, 224, 224, 0.8);
  min-width: 600px; /* Ensure minimum width to display all content */
}

/* Container adjustments for mobile */
@media (max-width: 768px) {
  .admin-container {
    padding: 1.5rem 1rem;
  }
  
  .admin-grid-container {
    margin-left: -0.5rem; /* Negative margin to stretch container */
    margin-right: -0.5rem;
    width: calc(100% + 1rem);
  }
}

/* Smaller mobile adjustments */
@media (max-width: 480px) {
  .admin-container {
    padding: 1rem 0.5rem;
  }
  
  /* Adjust typography */
  .admin-title {
    font-size: 1.25rem !important; 
  }
  
  /* Make the buttons stack on very small screens */
  .admin-buttons {
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }
  
  .admin-button {
    margin-right: 0;
    margin-bottom: 8px;
  }
  
  /* Ensure grid scrolls well */
  .admin-grid-container {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
    width: calc(100% + 1rem);
  }
  
  /* Visual indicator that grid scrolls */
  .admin-grid-container::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 20px;
    background: linear-gradient(to right, transparent, rgba(255, 255, 255, 0.7));
    pointer-events: none;
    opacity: 0.8;
  }
}

/* MUI DataGrid mobile overrides */
@media (max-width: 480px) {
  .MuiDataGrid-root {
    font-size: 0.75rem !important;
  }
  
  .MuiDataGrid-columnHeaders {
    min-height: 40px !important;
  }
  
  .MuiDataGrid-cell {
    padding: 4px 8px !important;
  }
}

.admin-input-rows {
  margin-top: 20px;
}

.admin-input-row {
  margin-bottom: 20px;
  padding: 10px;
  border-radius: 8px;
  background-color: #f9f9f9;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.admin-input-row .MuiTextField-root {
  background-color: white;
  border-radius: 4px;
}

.admin-input-row .MuiButton-root {
  height: 100%;
}

.admin-submit-button {
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 1rem;
  font-weight: 600;
  border-radius: 8px;
  background-color: #1976d2;
  color: white;
  transition: background-color 0.3s ease;
}

.admin-submit-button:hover {
  background-color: #1565c0;
}

/*
  =============== 
  Global
  ===============
*/

.c-button {
  padding: 10px;
  background-color: #b0856a;
  font-size: medium;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
  margin-bottom: 5px;
  margin-left: 5px;
  margin-right: 5px;
}



.c-button:hover {
  background-color: #926b51;
}

.c-button.confirm {
  background-color: #7cbb64;
  margin-right: 10px;
  width: 100px;
  height: 40px;
}

.c-button:hover.confirm {
  background-color: #a5da90;
}

.c-button.cancel {
  background-color: #cc6a52;
  margin-left: 10px;
  width: 70px;
  height: 40px;
}

.c-button:hover.cancel {
  background-color: #c78676;
}

/* Dashboard welcome/default view styling */
.dashboard-welcome {
  text-align: center;
  padding: 2.5rem 3rem;
}

.dashboard-welcome-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
}

.dashboard-welcome-icon {
  width: 70px;
  height: 70px;
  background-color: rgba(146, 107, 81, 0.1);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.5rem;
}

.dashboard-welcome-icon svg {
  width: 40px;
  height: 40px;
  color: #926b51;
}

.dashboard-welcome-text {
  font-size: 1.1rem;
  color: #555;
  max-width: 600px;
  margin: 0 auto;
}

.dashboard-welcome-options {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  gap: 1.5rem;
  width: 100%;
  margin-top: 1rem;
}

.dashboard-welcome-option {
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 12px;
  padding: 1.5rem;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border-left: 3px solid #926b51;
}

.dashboard-welcome-option:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
}

.dashboard-welcome-option h3 {
  color: #926b51;
  margin-top: 0;
  margin-bottom: 0.75rem;
  font-size: 1.2rem;
  font-weight: 600;
}

.dashboard-welcome-option p {
  color: #555;
  margin: 0;
  line-height: 1.5;
  font-size: 0.95rem;
}

.dashboard-welcome-address {
  background-color: rgba(146, 107, 81, 0.05);
  border-radius: 8px;
  padding: 1rem 1.5rem;
  margin-top: 1rem;
  border-left: 3px solid #926b51;
}

.dashboard-welcome-address p {
  margin: 0;
  color: #555;
}

.dashboard-welcome-address strong {
  color: #333;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .dashboard-welcome {
    padding: 2rem;
  }
  
  .dashboard-welcome-options {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 480px) {
  .dashboard-welcome {
    padding: 1.5rem;
  }
  
  .dashboard-welcome-icon {
    width: 60px;
    height: 60px;
  }
  
  .dashboard-welcome-icon svg {
    width: 30px;
    height: 30px;
  }
}

/* Enhanced dashboard transitions */
.dashboard-view-container {
  position: relative;
  width: 100%;
}

/* Entry animation */
.dashboard-transition-enter {
  opacity: 0;
  transform: translateX(30px);
}

.dashboard-transition-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 400ms cubic-bezier(0.34, 1.56, 0.64, 1), 
              transform 400ms cubic-bezier(0.34, 1.56, 0.64, 1);
}

/* Exit animation */
.dashboard-transition-exit {
  opacity: 1;
  transform: translateX(0);
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
}

.dashboard-transition-exit-active {
  opacity: 0;
  transform: translateX(-30px);
  transition: opacity 300ms cubic-bezier(0.4, 0, 0.2, 1), 
              transform 300ms cubic-bezier(0.4, 0, 0.2, 1);
}

/* Content animation to create a staggered effect */
.dashboard-panel {
  position: relative;
}

/* Staggered animations for panel children */
.dashboard-panel .dashboard-panel-title,
.dashboard-panel .dashboard-content,
.dashboard-panel .dashboard-payment-status,
.dashboard-panel .dashboard-welcome-content,
.dashboard-panel .dashboard-welcome-icon,
.dashboard-panel .dashboard-welcome-text,
.dashboard-panel .dashboard-welcome-options,
.dashboard-panel .dashboard-welcome-address {
  opacity: 0;
  transform: translateY(15px);
  animation: content-fade-in 600ms forwards;
}

/* Staggered delays for different elements */
.dashboard-panel .dashboard-panel-title {
  animation-delay: 100ms;
}

.dashboard-panel .dashboard-payment-status,
.dashboard-panel .dashboard-content,
.dashboard-panel .dashboard-welcome-icon {
  animation-delay: 200ms;
}

.dashboard-panel .dashboard-welcome-text {
  animation-delay: 300ms;
}

.dashboard-panel .dashboard-welcome-options {
  animation-delay: 400ms;
}

/* Individual option items stagger */
.dashboard-welcome-option:nth-child(1) {
  animation: option-fade-in 600ms 500ms forwards;
  opacity: 0;
  transform: translateY(15px);
}

.dashboard-welcome-option:nth-child(2) {
  animation: option-fade-in 600ms 600ms forwards;
  opacity: 0;
  transform: translateY(15px);
}

.dashboard-welcome-option:nth-child(3) {
  animation: option-fade-in 600ms 700ms forwards;
  opacity: 0;
  transform: translateY(15px);
}

.dashboard-welcome-address {
  animation-delay: 800ms !important;
}

/* Keyframes for content fade in */
@keyframes content-fade-in {
  from {
    opacity: 0;
    transform: translateY(15px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Keyframes for option items with slight bounce */
@keyframes option-fade-in {
  0% {
    opacity: 0;
    transform: translateY(15px);
  }
  70% {
    opacity: 1;
    transform: translateY(-5px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/*
  =============== 
  Modern Button Styles - Global
  ===============
*/

/* Base button styles for all non-nav buttons - softer appearance */
.c-button,
.login-button, 
.switch-button,
.auth-button,
.dashboard-button,
.admin-button,
.admin-submit-button {
  padding: 0.75rem 1.5rem;
  font-size: 0.95rem;
  font-weight: 500; /* Lighter weight for less blocky feel */
  border: none;
  border-radius: 12px; /* More rounded corners */
  cursor: pointer;
  transition: all 0.25s cubic-bezier(0.4, 0, 0.2, 1); /* Smoother easing */
  position: relative;
  overflow: hidden;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.06); /* Softer shadow */
  letter-spacing: 0.03em; /* Slightly more letter spacing */
  display: inline-flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

/* Button hover effect - smoother lift */
.c-button:hover,
.login-button:hover, 
.switch-button:hover,
.auth-button:hover,
.dashboard-button:hover,
.admin-button:hover,
.admin-submit-button:hover {
  transform: translateY(-3px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.08);
}

/* Button active state */
.c-button:active,
.login-button:active, 
.switch-button:active,
.auth-button:active,
.dashboard-button:active,
.admin-button:active,
admin-submit-button:active {
  transform: translateY(1px);
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.05);
}

/* Button focus state */
.c-button:focus,
.login-button:focus, 
.switch-button:focus,
.auth-button:focus,
.dashboard-button:focus,
.admin-button:focus,
admin-submit-button:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(146, 107, 81, 0.3);
}

/* Default color scheme - softer gradients */
.c-button,
.login-button, 
.switch-button,
.auth-button,
.dashboard-button {
  background: linear-gradient(to right, #9a7359, #85634c);
  color: white;
}

.c-button:hover,
.login-button:hover, 
.switch-button:hover,
.auth-button:hover,
.dashboard-button:hover {
  background: linear-gradient(to right, #a47b5f, #94704e);
}

/* Confirm button - softer green */
.c-button.confirm {
  background: linear-gradient(to right, #7db665, #6ca056);
  width: auto;
  min-width: 100px;
  height: auto;
  padding: 0.75rem 1.5rem;
  margin-right: 1rem;
}

.c-button.confirm:hover {
  background: linear-gradient(to right, #8ac373, #75ab5d);
}

/* Cancel button - softer red */
.c-button.cancel {
  background: linear-gradient(to right, #d17156, #c05e4a);
  width: auto;
  min-width: 70px;
  height: auto;
  padding: 0.75rem 1.25rem;
  margin-left: 1rem;
}

/* Admin button styling - softer appearance */
.admin-button {
  background: linear-gradient(to right, #9a7359, #85634c);
  color: white;
  opacity: 0.95;
}

/* Admin submit button - softer */
.admin-submit-button {
  background: linear-gradient(to right, #9a7359, #85634c);
  color: white;
  padding: 0.85rem 1.75rem;
  margin-top: 1.5rem;
  font-size: 1rem;
  font-weight: 600;
  box-shadow: 0 4px 10px rgba(146, 107, 81, 0.15);
}

/* Button loading state */
.c-button.loading,
.login-button.loading,
.dashboard-button.loading {
  position: relative;
  color: transparent;
}

.c-button.loading::after,
.login-button.loading::after,
.dashboard-button.loading::after {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  top: 50%;
  left: 50%;
  margin-top: -10px;
  margin-left: -10px;
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-top-color: white;
  border-radius: 50%;
  animation: button-loading-spinner 0.8s linear infinite;
}

@keyframes button-loading-spinner {
  to {
    transform: rotate(360deg);
  }
}

/* Small screen adaptations */
@media (max-width: 768px) {
  .c-button,
  .login-button, 
  .switch-button,
  .auth-button,
  .dashboard-button,
  .admin-button,
  .admin-submit-button {
    padding: 0.7rem 1.2rem;
    font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  .c-button,
  .login-button, 
  .switch-button,
  .auth-button,
  .dashboard-button,
  .admin-button,
  .admin-submit-button {
    padding: 0.65rem 1.1rem;
    font-size: 0.85rem;
    width: 100%;
    margin: 0.5rem 0;
  }
  
  .c-button.confirm,
  .c-button.cancel {
    margin: 0.5rem 0;
  }
}