.snackbar {
  position: fixed;
  top: 30px; /* Change from bottom to top */
  left: 50%;
  transform: translateX(-50%);
  z-index: 9999;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  transition: opacity 0.3s ease, transform 0.3s ease;
  max-width: 90%;
  width: auto;
  min-width: 300px;
}

.snackbar-visible {
  opacity: 1;
  transform: translateX(-50%) translateY(0);
}

.snackbar-hidden {
  opacity: 0;
  transform: translateX(-50%) translateY(-20px); /* Change from positive to negative */
}

.snackbar-content {
  display: flex;
  align-items: center;
  padding: 14px 16px;
  border-radius: 8px;
}

.snackbar-icon {
  margin-right: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.snackbar-message {
  flex-grow: 1;
  font-size: 0.95rem;
  line-height: 1.4;
}

.snackbar-close {
  background: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  margin-left: 8px;
  border-radius: 50%;
  opacity: 0.7;
  transition: opacity 0.2s;
}

.snackbar-close:hover {
  opacity: 1;
}

/* Success variant */
.snackbar-success .snackbar-content {
  background-color: #edf7ed;
  color: #1e4620;
}

.snackbar-success .snackbar-icon svg {
  color: #4caf50;
}

.snackbar-success .snackbar-close {
  color: #1e4620;
}

/* Error variant */
.snackbar-error .snackbar-content {
  background-color: #fdeded;
  color: #5f2120;
}

.snackbar-error .snackbar-icon svg {
  color: #ef5350;
}

.snackbar-error .snackbar-close {
  color: #5f2120;
}

/* Warning variant */
.snackbar-warning .snackbar-content {
  background-color: #fff4e5;
  color: #663c00;
}

.snackbar-warning .snackbar-icon svg {
  color: #ff9800;
}

.snackbar-warning .snackbar-close {
  color: #663c00;
}

/* Info variant */
.snackbar-info .snackbar-content {
  background-color: #e8f4fd;
  color: #0c3c60;
}

.snackbar-info .snackbar-icon svg {
  color: #2196f3;
}

.snackbar-info .snackbar-close {
  color: #0c3c60;
}

/* Responsive adjustments */
@media (max-width: 600px) {
  .snackbar {
    top: 16px; /* Change from bottom to top */
    min-width: auto;
    width: 90%;
  }
  
  .snackbar-content {
    padding: 12px;
  }
}