.email-verification-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 80vh;
  padding: 2rem;
  background-color: #f9f9f9;
}

.email-verification-content {
  text-align: center;
  background: rgba(255, 255, 255, 0.85);
  backdrop-filter: blur(5px);
  border-radius: 12px;
  padding: 2.5rem 3rem;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.08);
}

.email-verification-spinner {
  margin-bottom: 1rem;
  color: #1976d2;
}

.email-verification-title {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.email-verification-message {
  font-size: 1rem;
  color: #555;
}